.flex {
  display: flex;
}
.flex-column {
  @extend .flex;
  flex-direction: column;
}
.flex-row-centered {
  @extend .flex;
  justify-content: center;
}

.flex-column-centered {
  @extend .flex-column;
  align-items: center;
}
.flex-row-space-between {
  @extend .flex;
  justify-content: space-between;
}
