@import 'normalize';
@import 'typography';
@import 'colors';
@import 'helpers';
@import 'component-modifiers';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select {
  padding: 4px 10px;
  border: solid 1px var(--cdcdcd);

  &.ng-select-single .ng-select-container {
    border: 0 !important;

    .ng-value-container .ng-input {
      padding-left: 0;
    }
  }
}

:root {
  --toolbar-background: var(--no-name-14);
  --checkbox-background: var(--greyish-blue);
  --checkbox-offset: 0;
  --thema-core-button-color: var(--primary);
  --order-summary-last-row-padding: 30px 0;
  --order-summary-section-spacing: 20px;
  --order-summary-base-spacing: 15px;
  --main-height: calc(100vh - 52px);
  --inactive-breadcrumbs-color: var(--666);
  --darker-table-row: var(--grey);
  --main-table-header: var(--greyish-blue);
}

#CybotCookiebotDialogTabContent .CybotCookiebotDialogBodyLevelButtonSlider { background: #bfbec4 !important; }
#CybotCookiebotDialogTabContent input:checked+.CybotCookiebotDialogBodyLevelButtonSlider { background: var(--primary) !important; }
#CybotCookiebotDialogHeader { display: none !important; }
#CybotCookiebotDialog { border-radius: 0 !important; }

// freshchat customization start

div.chat-fc-form-outer {
  font-family: 'Lato', sans-serif !important;

  p {
    font-family: inherit;
  }

  a.fc-button {
    font-family: inherit;
    text-decoration: none;
  }
}

.chat-fc-form-outer div.fc-form ul li {
  @extend .font-paragraph;

  label {
    display: block;
    height: 1em;
  }

  input {
    padding-inline: 10px !important;
  }
}

// freshchat customization end

.background-pattern-color {
  background: var(--toolbar-background);
  background-image: url("./assets/images/pattern-light-05-opacity.png");
  background-size: 100px;
}

.background-pattern {
  background-color: var(--no-name-14);
  background-image: url('/assets/footer/footer-bg.jpg');
}

.ng-select-container {
  border: none !important;
  box-shadow: none !important;
  border-bottom: solid 1px var(--half-baked) !important;
  border-radius: unset !important;
}

.ng-arrow-wrapper {
  width: 20px !important;
  padding-right: 0 !important;
  margin-left: 6px;
}

.ng-arrow {
  border-top-color: var(--black) !important;
  border-width: 7px 5px 1px !important;
}

.ng-value-container {
  padding-left: 0 !important;
}

.ng-clear-wrapper {
  display: none;
}

* {
  outline: none;
}

body {
  background-color: var(--white);
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: none;
}

@-moz-document url-prefix() {
  .fffix-country-select > thema-core-select {
    select {
      width: 77px !important;
    }
  }
}


main {
  min-height: var(--main-height);

  @media screen and (min-width: 1280px) {
    --main-height: calc(100vh - 305px);
  }
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 0 !important;
}

.gm-style-iw.gm-style-iw-c {
  max-width: unset !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;

  &.space-between {
    justify-content: space-between;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.ai-center {
    align-items: center;
  }
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
}

svg-icon.svg-icon-help {
  cursor: pointer;

  & > svg > path {
    fill: #616E7C;
  }
}

thema-core-contact {
  .contact-details-subgroup {
    address, a {
      font-style: normal !important;
    }
    a {
      text-decoration: underline !important;
    }
  }
  h1 {
    font-family: 'Piazzolla', serif;
    font-size: 3.6rem !important;
    font-weight: 500;
  }
  h4, h6 {
    font-family: 'Lato', sans-serif;
    font-size: 1.4rem !important;
  }
  h4:first-of-type {
    font-family: 'Piazzolla', serif;
    font-size: 1.8rem !important;
    font-weight: 500;
  }
}

.cdx-no-linebreak {
  white-space: nowrap;
}

.global-warning {
  background: var(--snack-warn);
  color: var(--emperor);
  padding: 20px;
  text-align: center;

  & > div {
    max-width: 1240px;
    margin: 0 auto;
  }
}

.global-error {
  @extend .global-warning;
  background: var(--snack-error);
}
