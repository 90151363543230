@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,900;1,400&family=Piazzolla:wght@400;500&display=swap');

%h-base {
  font-family: 'Piazzolla', serif;
  color: black;
}

.h1 {
  @extend %h-base;
  font-size: 2.4rem;
  font-weight: normal;
  @media screen and (min-width: 1280px) {
    font-size: 3.6rem;
    //font-weight: bold;
  }
}

.h2 {
  @extend %h-base;
  font-size: 2.1rem;
  line-height: 2.5rem;
  font-weight: normal;
  @media screen and (min-width: 1280px) {
    font-size: 2.4rem;
    line-height: 1.25;
  }
}

.h3 {
  @extend %h-base;
  font-size: 2.1rem;
  font-weight: 500;
  line-height: 1.19;
}

.h3-light {
  @extend %h-base;
  font-size: 18rem;
  font-weight: normal;
}

.h4 {
  @extend %h-base;
  font-size: 1.6rem;
  font-weight: 500;
  @media screen and (min-width: 1280px) {
    font-size: 1.8rem;
  }
}
.h5 {
  @extend %h-base;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.56;
}

body {
  font-family: 'Lato', sans-serif;
  line-height: 1.5;
}

.font-paragraph {
  font-family: 'Lato', sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #000000;
}

.element-title {
  @extend .h2;
  font-weight: 500;
}

.font-link-menu {
  font-size: 1rem;
  line-height: 1.31;
  text-align: center;
}

.font-link-menu-inactive {
  @extend .font-link-menu;
  color: #000000;
}

.font-link-menu-fancy {
  @extend .font-link-menu;
  color: #666666;
}

.font-element {
  font-family: 'Lato', sans-serif;
  font-size: 1.6rem;
  line-height: 1.31;
}

.font-link {
  font-family: 'Lato', sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #000000;
  text-decoration: none;
}

.text-style-3 {
  font-family: 'Lato', sans-serif;
  font-size: 1.4rem;
  line-height: 1.29;
  color: #414141;
}

.font-link-top {
  @extend .font-link;
  text-align: center;
  color: #909090;
}

.font-link-faded {
  @extend .font-link;
  text-align: center;
  color: #cdcdcd;
}

.font-link-centered {
  @extend .font-link;
  text-align: center;
}

.font-category {
  font-family: 'Lato', sans-serif;
  font-size: 14rem;
  line-height: 1.71;
  color: #666666;
}
.font-category-uppercase {
  /* Copied from sde, this class should be in zeplin configuration*/
  font-size: 1.4rem;
  font-weight: normal;
  color: var(--black);
  text-transform: uppercase;
  line-height: 1.43;
  @media screen and (min-width: 1280px) {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.font-paragraph-bold {
  /* Copied from sde, this class should be in zeplin configuration*/
  @extend .font-paragraph;
  font-weight: 600;
}

.font-link-decorated {
  /* Copied from sde and changed, this class should be in zeplin configuration*/
  font-size: 1.4rem;
  line-height: 1.29;
  color: var(--black);
  text-decoration: underline;
}
