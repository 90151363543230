.ed-summary {
  ::ng-deep {
    summary {
      line-height: 2.4;
      padding: 20px 0;

      &::before {
        left: unset;
        right: 4px;
        top: 36px;
        border-top-color: var(--cdcdcd)
      }

      &::after {
        top: 32px;
        left: unset;
        right: 8px;
        border-right-color: var(--cdcdcd)
      }

      &, & + p {
        padding-left: 0;
        font-family: 'Lato', sans-serif;
        font-size: 1.4rem;
        color: black;
      }

      & + p {
        line-height: 2.1;
        position: relative;
        top: -10px;
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }
}

.ed-diamond-visual-guide {
  &::ng-deep {
    header, section {
      margin-top: 0;
      font-size: 1.4rem;
      width: 770px;
      color: var(--no-name-9)
    }

    section {
      &:first-of-type {
        margin-bottom: 60px;
      }
    }

    thema-core-diamond-color-guide {
      grid-column-gap: 15px;
      grid-template-columns: repeat(9, 72px);
      grid-template-rows: 125px 31px;

      &:not(.with-markers) {
        grid-template-rows: 125px;
      }

      .range-marker {
        font-size: 1.4rem;
        color: var(--black);

        div:first-of-type {
          height: 12px;
          margin-bottom: 3px;
        }
      }

      .color-gradient {
        width: 591px;
        height: 12px;
        right: calc(50% - 385px);
      }
    }

    thema-core-diamond-clarity-guide {
      grid-column-gap: 0;
      grid-template-columns: repeat1(11, 72px);
      grid-template-rows: 125px;
    }

    thema-core-diamond-color-guide, thema-core-diamond-clarity-guide {
      thema-core-diamond-attribute-box {
        width: 72px;
        padding: 10px 0;

        &.active {
          border: solid 1px var(--no-name-3);
        }

        div:last-of-type {
          color: var(--black);
          margin: 0 10px 0 11px;
          line-height: 1.6rem;
        }

        svg-icon {
          margin: 0;
          width: 57px;
          height: 37px;
        }

        img {
          margin-top: 5px;
          margin-bottom: 9px;
          max-width: 35px;
        }
      }
    }
  }
}
