:root {
  --black: #000;
  --white: #fff;
  --newsletter-success: green;
  --cerulean: red;
  --background-navy: rgb(29, 59, 90);
  --checkbox-background: rgb(29, 59, 90);
  --background-navy-08: #1d3b5acc;
  --bismark: #4c7289;
  --half-baked: #91bbd1;
  --emperor: #555555;
  --catskill-white: #e3f0f2;
  --gallery: #eaeaea;
  --whiskers: #c9c9c9;
  --black-two: #222;
  --pale-grey: #f0f1f3;
  --grey: #f5f5f5;
  --warm-grey: #909090;
  --snack-success: #c6dfac;
  --snack-warn: #fff5a2;
  --snack-error: #ffd6d6;
  --date: #d8d8d8;
  /*Colors from zeplin without names*/
  --no-name-1: #283c51;
  --greyish-blue: #5c7fa3;
  --no-name-3: #cbcbcb;
  --no-name-4: #010222;
  --primary: #13102f;
  --no-name-6: rgba(212, 214, 219, 0.7);
  --no-name-7: rgba(19, 16, 47, 0.2);
  --no-name-8: #8d8d8d;
  --no-name-9: #666666;
  --no-name-10: #f4f4f6;
  --no-name-11: #e7e8eb;
  --no-name-12: #d4f3d1;
  --no-name-13: #414141;
  --no-name-14: #0F102D;
  --cdcdcd: #cdcdcd;
  --666: #666;

  /*Freestyle*/
  --input-background: #f5f5f5;
  --input-background-dark: #010222;
}

